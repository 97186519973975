.App {
  text-align: center;
}

.App-header {
  background-color: #3d2b56;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ccf5ac;
}

.groet {
  border-radius: 10px;
  padding: 10px;
  user-select: none; 
}

.groet:hover {
  background-color: #2f2043;
  cursor: pointer;
}

.groet:active {
  background-color: #221632;
}

.loading:after {
  content: '.';
  animation: dots 1.5s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #ccf5ac;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 #ccf5ac,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 #ccf5ac,
      .5em 0 0 #ccf5ac;}
}

.hidden {
  display: none;
}